<template>
	<div class="container">
		<baseTable
			:defaultShowQueryItemNum="6"
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			@rowClick="rowClick"
			:activeTab="activeTab"
			:otherBtns="otherBtns"
			custom
			showReset
		/>
	</div>
</template>

<script>
/* 数据详情 */
import baseTable from '@/views/components/components-base-table';
import moment from 'moment';
import { setUrlParams, downloadByCreateA } from '@/utils/util';
export default {
	name: 'allEvaluation',
	components: {
		baseTable
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		applyTypeList() {
			return this.$store.state.approval.applyTypeList;
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		statusOpts() {
			return this.getOptsById('innerTalentStatus');
		},
		rankNameOpts() {
			return this.getOptsById('rankName');
		},
		positionMatchOpts() {
			return this.getOptsById('positionMatch');
		},
		abilityLevelOpts() {
			return this.getOptsById('abilityLevel');
		},
		rankLevelOpts() {
			return this.getOptsById('rankLevel');
		},
		talentTypeOpts() {
			return this.getOptsById('talentType');
		},
		appointmentOpts() {
			return this.getOptsById('appointment');
		},
		otherBtns() {
			return [
				this.permissionControlBtns(this.pageName, 'Export') && {
					icon: 'el-icon-download',
					label: '导出',
					click: () => {
						const params = { ...this.queryData };

						this.$delete(params, 'pageNo');
						this.$delete(params, 'pageSize');
						downloadByCreateA(`/v1/internal/talent/exportReportDetail?params=${encodeURIComponent(JSON.stringify(params))}`);
					}
				}
			].filter((v) => v);
		}
	},
	data() {
		return {
			queryData: {},
			tableCols: [
				{
					label: '计划信息',
					key: 'planInfo',
					children: [
						{
							label: '序号',
							key: 'seqNo',
							width: '80px'
						},
						{
							label: '计划名称',
							key: 'planName',
							width: '120px'
						},
						{
							label: '公司名称',
							key: 'company',
							width: '120px'
						}
					]
				},

				{
					label: '被评估人信息',
					key: 'userNamexx',
					children: [
						{
							label: '姓名',
							key: 'userName'
						},
						{
							label: '一级组织',
							key: 'firstOrg'
						},
						{
							label: '二级组织',
							key: 'secondOrg'
						},
						{
							label: '三级组织',
							key: 'thirdOrg'
						},
						{
							label: '四级组织',
							key: 'fourthOrg'
						},
						{
							label: '职位',
							key: 'officeName'
						}
					]
				},
				{
					label: '评估师',
					key: 'pgs',
					children: [
						{
							label: '项目经理',
							key: 'projectManagerName'
						},
						{
							label: '评估人',
							key: 'evaluateUserName'
						},
						{
							label: '复核人',
							key: 'reviewUserName'
						}
					]
				},
				{
					label: '管理站位评估',
					key: 'glzw',
					children: [
						{
							label: '初始站位',
							key: 'initialPosition',
							formatter: (v) => this.rankNameOpts.find(({ dictId }) => dictId === v)?.dictName
						},
						{
							label: '实际站位',
							key: 'actualPosition',
							formatter: (v) => this.rankNameOpts.find(({ dictId }) => dictId === v)?.dictName
						},
						{
							label: '站位匹配度',
							key: 'positionMatch',
							formatter: (v) => this.positionMatchOpts.find(({ dictId }) => dictId === v)?.dictName
						}
					]
				},
				{
					label: '管理能力评估（适用于全部管理层级）',
					key: 'glnlpg',
					children: [
						{
							label: '战略能力等级评估',
							key: 'strategy'
						},
						{
							label: '战略能力评估',
							key: 'strategyMatch',
							formatter: (v) => this.abilityLevelOpts.find(({ dictId }) => dictId === v)?.dictName
						},
						{
							label: '变革能力等级评估',
							key: 'changeExpert'
						},
						{
							label: '变革能力评估',
							key: 'changeMatch',
							formatter: (v) => this.abilityLevelOpts.find(({ dictId }) => dictId === v)?.dictName
						},
						{
							label: '高效体系构建能力等级评估',
							key: 'flowExpert'
						},
						{
							label: '高效体系构建能力评估',
							key: 'flowMatch',
							formatter: (v) => this.abilityLevelOpts.find(({ dictId }) => dictId === v)?.dictName
						},
						{
							label: '人才队伍建设能力等级评估',
							key: 'employeeAdvocate'
						},
						{
							label: '人才队伍建设能力评估',
							key: 'advocateMatch',
							formatter: (v) => this.abilityLevelOpts.find(({ dictId }) => dictId === v)?.dictName
						}
					]
				},
				{
					label: '现岗胜任',
					key: 'xgsr',
					children: [
						{
							label: '现岗胜任评估',
							key: 'rankLevel',
							formatter: (v) => this.rankLevelOpts.find(({ dictId }) => dictId === v)?.dictName
						}
					]
				},
				{
					label: '五项素质等级评估',
					key: 'wxszdj',
					children: [
						{
							label: '主动性',
							key: 'initiative'
						},
						{
							label: '概念思维',
							key: 'conceptThinking'
						},
						{
							label: '影响力',
							key: 'influence'
						},
						{
							label: '成就导向',
							key: 'achievementGuide'
						},
						{
							label: '坚韧性',
							key: 'hardiness'
						},
						{
							label: '人才类型',
							key: 'talentType',
							formatter: (v) => this.talentTypeOpts.find(({ dictId }) => dictId === v)?.dictName
						},
						{
							label: '任用建议',
							key: 'appointment',
							formatter: (v) => this.appointmentOpts.find(({ dictId }) => dictId === v)?.dictName
						}
					]
				},
				{
					label: '评估开始时间',
					key: 'startTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				},
				{
					label: '评估结束时间',
					key: 'endTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				}
				// {
				// 	label: '截止时间',
				// 	key: 'endTime',
				// 	formatter: (val) => moment(val).format('YYYY-MM-DD')
				// },
				// {
				// 	label: '评估状态',
				// 	key: 'status',
				// 	width: 120,
				// 	formatter: (v) => this.statusOpts.find((it) => it.value === v)?.label
				// }
			],
			queryItem: [
				{
					label: '计划名称',
					key: 'planId',
					type: 'selectSearch',
					async: (query) =>
						this.$axios
							.post('/v1/internal/talent/getPlanList', {
								request: {
									pageNo: 1,
									pageSize: 40,
									planName: query,
									pageType: (this.pageName === 'DataDetails_personal' && '2') || ''
								}
							})
							.then((res) => res.list?.map(({ planName, id }) => ({ dictId: id, dictName: planName }))),
					span: 6
				},
				{
					label: '状态',
					key: 'status',
					type: 'select',
					span: 6,
					options: () => this.statusOpts
				},
				{ label: '公司名称', key: 'company', type: 'input', span: 6 },
				{ label: '评估类型', key: 'evaluationType', type: 'select', span: 6, groupId: 'evaluationType' },
				// { label: '区域', key: 'qy', type: 'input', span: 6 },
				{
					label: '所属省份城市',
					key: 'city', // provinceCode cityCode
					type: 'cascader',
					options: window.$cityList,
					props: {
						label: 'label',
						value: 'value',
						children: 'children'
					},
					// showAllLevels: false,
					span: 6
				},
				{
					label: '行业',
					key: 'industry',
					type: 'cascader',
					async: () => this.$axios.get('/v1/common/getIndustryList'),
					props: {
						label: 'name',
						value: 'id',
						children: 'child',
						emitPath: false
					},
					showAllLevels: false,
					// options: window.$industryList,
					span: 6
				},
				{
					label: '属于行业标杆',
					key: 'isIndustryBenchmark',
					type: 'select',
					span: 6,
					options: [
						{ dictName: '是', dictId: '1' },
						{ dictName: '否', dictId: '0' }
					]
				},
				this.pageName === 'DataDetails' && {
					label: '项目经理',
					key: 'projectManager',
					showKey: 'projectManagerName',
					type: 'selectSearch',
					span: 6,
					firstRequest: true, // 开始直接请求一次
					/* projectRole 项目角色 1-项目经理 2-复核人 3-评估人 */
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '1' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name })))
				},
				// { label: '评估人', key: 'evaluateUserName', type: 'input', span: 6 },
				{
					label: '评估人',
					key: 'evaluateUserId',
					showKey: 'evaluateUserName',
					type: 'selectSearch',
					span: 6,
					firstRequest: true, // 开始直接请求一次
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '3' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name })))
				},
				{
					label: '复核人',
					key: 'reviewUserId',
					showKey: 'reviewUserName',
					type: 'selectSearch',
					span: 6,
					firstRequest: true,
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '2' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name })))
				},
				{ label: '被评估人', key: 'userName', type: 'input', span: 6 },
				// {
				// 	label: '组织',
				// 	key: 'orgId',
				// 	type: 'tree',
				// 	async: () => this.$axios.post('/v1/org/list', { request: {} }),
				// 	props: { children: 'children', label: 'orgName' },
				// 	nodeKey: 'id',
				// 	nodeName: 'orgName',
				// 	showKey: 'orgName',
				// 	filter: true,
				// 	span: 6
				// },
				// { label: '职位', key: 'officeName', type: 'input', span: 6 },
				{
					label: '原始站位',
					key: 'initialPosition',
					type: 'select',
					groupId: 'rankName',
					span: 6
				},
				{ label: '实际站位', key: 'actualPosition', type: 'select', groupId: 'rankName', span: 6 },
				// { label: '站位匹配度', key: 'zwpp', type: 'input', span: 6 },
				{
					label: '站位匹配度',
					key: 'positionMatch',
					type: 'select',
					groupId: 'positionMatch',
					span: 6
				},
				{ label: '战略能力', key: 'strategyMatch', type: 'select', options: () => this.abilityLevelOpts, span: 6 },
				{ label: '变革能力', key: 'changeMatch', type: 'select', options: () => this.abilityLevelOpts, span: 6 },
				{ label: '高效体系构建能力', key: 'flowMatch', type: 'select', options: () => this.abilityLevelOpts, span: 6 },
				{ label: '人才队伍建设能力', key: 'advocateMatch', type: 'select', options: () => this.abilityLevelOpts, span: 6 },
				{ label: '岗位胜任度', key: 'rankLevel', type: 'select', options: () => this.rankLevelOpts, span: 6 },
				{ label: '主动性', key: 'initiative', type: 'select', groupId: 'abilityGrade', span: 6 },
				{ label: '概念思维', key: 'conceptThinking', type: 'select', groupId: 'abilityGrade', span: 6 },
				{ label: '影响力', key: 'influence', type: 'select', groupId: 'abilityGrade', span: 6 },
				{ label: '成就导向性', key: 'achievementGuide', type: 'select', groupId: 'abilityGrade', span: 6 },
				{ label: '坚韧性', key: 'hardiness', type: 'select', groupId: 'abilityGrade', span: 6 },
				{ label: '人才类型', key: 'talentType', type: 'select', options: () => this.talentTypeOpts, span: 6 }
			].filter((v) => v),

			formModel: {}
		};
	},
	created() {},
	mounted() {
		// getApprovalTypeOnce();
	},
	methods: {
		getTableData(params, callback) {
			const { city } = params.request;
			const data = params.request;

			if (city?.length) {
				data.provinceCode = city[0];
				data.cityCode = city[1];
				this.$delete(data, 'city');
			}
			this.queryData = params.request;
			if (this.pageName === 'DataDetails_personal') {
				params.request.pageType = '2';
			}
			callback(this.$axios.post('/v1/internal/talent/getReportList', params));
		},
		// 点击table行
		rowClick({ planId, userId, evaluationType }) {
			this.$router.push({
				name: 'EvaluationDetail',
				query: { params: setUrlParams({ userId, planId, evaluationType, type: 'view', pageName: this.pageName }) }
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>
