<template>
	<div>
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
			:activeTab="activeTab"
			custom
			sort
		/>
		<el-dialog
			width="600px"
			title="生成新报告"
			top="20vh"
			center
			:visible.sync="createVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItemWrap"
				:formItems="dialogItems"
				@submit="this.submitForm"
				@cancel="createVisible = false"
				:defaultData="defaultData"
				style="margin: 0 50px"
			/>
		</el-dialog>
	</div>
</template>

<script>
/* 评估结果-评估报告 */
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import { Loading } from 'element-ui';
import { setUrlParams } from '@/utils/util';

export default {
	name: 'EvaluationReport',
	components: {
		baseTable,
		formItem
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		currentPageName() {
			return this.pageName || this.$route.name;
		},
		otherBtns() {
			return [
				this.permissionControlBtns(this.currentPageName, 'Generate') && {
					// icon: 'el-icon-download',
					label: '生成新报告',
					click: () => {
						this.defaultData = {};
						this.createVisible = true;
					}
				}
			].filter((v) => v);
		},
		reportPath() {
			if (this.pageName === 'EvaluationReport') {
				return '/projectPptReport';
			}
			return '/useTalentCorrectly/talentEvaluation/report';
		},
		queryItem() {
			return [
				{
					label: '评估计划',
					key: 'planId',
					type: 'selectSearch',
					firstRequest: true,
					async: (planName) =>
						this.$axios
							.post('/v1/internal/talent/getPlanList', {
								request: { planName, pageNo: 1, pageSize: 40, pageType: (this.currentPageName === 'EvaluationReport' && '2') || '' }
							})
							.then((res) => res.list.map(({ planName, id }) => ({ dictId: id, dictName: planName }))),
					span: 6
				},
				{ label: '公司名称', key: 'company', type: 'input', span: 6 },
				this.currentPageName === 'AssessmentReport' && {
					label: '项目经理',
					key: 'projectManager',
					type: 'selectSearch',
					span: 6,
					firstRequest: true, // 开始直接请求一次
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '1' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name })))
				}
			].filter((v) => v);
		}
	},
	data() {
		return {
			createVisible: false,
			defaultData: {},
			tableCols: [
				{
					label: '计划名称',
					key: 'planName'
				},
				{
					label: '公司名称',
					key: 'company'
				},
				{
					label: '项目经理',
					key: 'projectManagerName'
				},
				{
					label: '评估时间',
					key: 'planTime'
				},
				{
					label: '报告版本',
					key: 'version',
					width: 180,
					showOverflow: false,
					render: ({ pptInfos }) => (
						<div style="line-height: 40px">
							{pptInfos.map(({ version }) => (
								<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title={version}>
									{version}
								</div>
							))}
						</div>
					)
				},
				{
					label: '操作',
					key: 'cz',
					width: '160px',
					fixed: 'right',
					showOverflow: false,
					render: (row) => (
						<div>
							{row.pptInfos.map(({ id }) => (
								<div>
									<el-button type="text" onClick={(e) => this.viewReport(e, id)}>
										查看
									</el-button>
									<el-button type="text" onClick={(e) => this.editReport(e, id, row)}>
										编辑
									</el-button>
									<el-button type="text" style="color: #fe1c1c" onClick={(e) => this.deleReport(e, id)}>
										删除
									</el-button>
								</div>
							))}
						</div>
					)
				}
			],
			dialogItems: [
				{
					label: '计划名称',
					key: 'planId',
					type: 'selectSearch',
					firstRequest: true,
					async: (planName) =>
						this.$axios
							.post('/v1/internal/talent/getPlanList', { request: { planName, pageNo: 1, pageSize: 40 } })
							.then((res) => res.list.map(({ planName, id }) => ({ dictId: id, dictName: planName }))),
					rules: [{ required: true, message: '请选择' }]
				},
				{
					label: '选择模板',
					key: 'model',
					type: 'select',
					options: [
						{ dictId: '1', dictName: '1-按照一级组织统计' },
						{ dictId: '2', dictName: '2-按照层级统计' }
					],
					rules: [{ required: true, message: '请选择' }]
				},
				{ label: '报告名称', key: 'name', type: 'input', rules: [{ required: true, message: '请填写' }] },
				{ label: '添加客户logo', key: 'customerLogo', type: 'upload', img2base64: true }
			],

			formModel: {}
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			if (this.pageName === 'EvaluationReport') {
				params.request.pageType = '2';
			}
			callback(this.$axios.post('/v1/internal/talent/getPptList', params));
		},
		// 点击table行
		rowClick() {},
		submitForm(form, data) {
			form.validate((valid) => {
				if (valid) {
					if (data.customerLogo) {
						data.customerLogo = data.customerLogo[0]?.url;
					}
					const loading = Loading.service({ target: '.formItemWrap' });

					this.$axios
						.post('/v1/internal/talent/createNewVersionPpt', { request: data })
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.createVisible = false;
							this.$message.success('提交成功！ 报告生成中...请稍后查看');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							loading.close();
							this.createVisible = false;
						});
				}
			});
		},
		viewReport(e, id) {
			e.stopPropagation();
			this.$router.push({ path: this.reportPath, query: { params: setUrlParams({ id, type: 'view' }) } });
		},
		editReport(e, id, { pptInfos, planId }) {
			e.stopPropagation();
			this.$router.push({ path: this.reportPath, query: { params: setUrlParams({ id, type: 'edit', pptInfos, planId }) } });
		},
		// 删除
		deleReport(e, id) {
			e.stopPropagation();
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.post('/v1/internal/talent/deletePpt', { request: { id } }).then((res) => {
						if (res.code) {
							return;
						}
						this.$message.success('删除成功');
						this.$refs.baseTable?.handleSearch();
					});
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 30px;
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>
