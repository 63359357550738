<template>
	<div class="xtable">
		<vxe-grid v-bind="config" ref="xgrid" @toolbar-tool-click="toolbarToolClickEvent" :id="`vxe_table_${config.id}`" @custom="customClick">
			<template #empty><Empty /></template>
		</vxe-grid>
	</div>
</template>

<script>
import Vue from 'vue';
import {
	VXETable,
	Edit,
	// 表格
	Table,
	Grid,
	Toolbar,
	Input,
	Button,
	Select,
	Pager,
	Footer,
	Export,
	Validator,
	Form
} from 'vxe-table';
import Empty from '@/views/components/components-empty/index.vue';
Vue.use(VXETable)
	.use(Edit)
	.use(Export)
	.use(Validator)
	.use(Table)
	.use(Grid)
	.use(Toolbar)
	.use(Input)
	.use(Footer)
	.use(Button)
	.use(Pager)
	.use(Select)
	.use(Form);
VXETable.setup({
	zIndex: 9999
});
export default {
	components: { Empty },
	props: {
		config: {
			type: Object,
			default: () => {}
		}
	},
	watch: {
		config: {
			deep: true,
			handler({ mergeCells }) {
				if (mergeCells && mergeCells.length) {
					this.$nextTick(() => {
						this.$refs.xgrid.setMergeCells(mergeCells);
					});
				}
			}
		}
	},
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {
		toolbarToolClickEvent({ code }) {
			const { xgrid } = this.$refs;
			const tools = this.config.toolbarConfig?.tools || [];

			tools.forEach((it) => {
				if (code === it.code) {
					it.click(xgrid);
				}
			});
		},
		customClick(e) {
			this.$emit('custom', e);
			switch (e.type) {
				case 'reset':
				case 'confirm':
					this.$refs.xgrid.setMergeCells(this.config.mergeCells);
					break;
				default:
					break;
			}
		}
	},
	beforeDestroy() {}
};
</script>

<style lang="scss">
.xtable {
	// padding: 20px;
	// .vxe-custom--option .vxe-checkbox--icon {
	// 	top: 0.12em;
	// }
	.vxe-grid {
		overflow-y: auto;
	}
	.vxe-table {
		min-height: calc(100vh - 300px);
		background: #fff;
		.vxe-table--render-wrapper {
			.vxe-table--header {
				.vxe-header--column {
					vertical-align: middle;
					padding: 4px 0;
					background-color: #f0f8ff;
				}
			}
		}
		.vxe-table--empty-placeholder {
			min-height: 300px;
		}
	}
	.vxe-body--column {
		padding: 0 !important;
		vertical-align: middle;
		// 	height: 48px;
		// 	line-height: 48px;
		// 	// display: inline-flex;
		// 	// align-items: center;
		// 	position: relative;
		.vxe-cell {
			width: 100%;
			height: 100%;
			padding: 0;
			display: inline-block;
			// .thbox {
			// 	width: 100%;
			// 	height: 100%;
			// }
		}
	}
	// .vxe-toolbar .vxe-custom--option-wrapper .vxe-custom--header {
	// 	padding: 4px 0;
	// }
	// .vxe-header--row {
	// 	.vxe-header--column {
	// 		// background: #f0f8ff;
	// 	}
	// }

	// .circular {
	// 	cursor: pointer;
	// 	height: 34px;
	// 	width: 75px;
	// 	border-radius: 121px / 50px;
	// 	display: inline-flex;
	// 	flex-direction: column;

	// 	position: absolute;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%, -50%);
	// 	.top {
	// 		border-bottom: 2px solid red;
	// 	}
	// 	.top,
	// 	.down {
	// 		width: 100%;
	// 		height: 50%;
	// 		display: inline-flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 		font-size: 12px;
	// 	}
	// }
	.vxe-table--body-wrapper.body--wrapper {
		height: 100% !important;
		// overflow: hidden;
	}
}
.el-select-dropdown.el-popper {
	z-index: 20000 !important;
}
</style>
