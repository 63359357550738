<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="数据详情" name="1" v-if="permissionControl('DataDetails')">
				<DataDetails ref="pane1" pageName="DataDetails"></DataDetails>
			</el-tab-pane>
			<!-- <el-tab-pane :lazy="true" label="数据统计" name="2" v-if="permissionControl('DataStatistics')">
				<DataStatistics ref="pane2" pageName="DataStatistics"></DataStatistics>
			</el-tab-pane> -->
		</el-tabs>
	</div>
</template>

<script>
/* 数据详情 数据统计*/
import DataDetails from '../evaluationResult/dataDetails';
// import DataStatistics from '../evaluationResult/dataStatistics';

export default {
	name: 'evaluationDatabaseTabs',
	components: {
		DataDetails
		// DataStatistics
	},
	props: { pageName: {} },
	data() {
		return {
			activeName: '1',
			listQuery: {}
		};
	},

	computed: {},
	created() {},
	methods: {},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.pageName}Data`));

		const { activeName, listQuery } = data || {};

		if (activeName) {
			this.activeName = activeName;
		}
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'DataDetails' },
			{ name: '2', key: 'DataStatistics' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.pageName}Data`,
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`pane${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>
