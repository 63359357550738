<template>
	<div>
		<TablePage :config="gridOptionsConfig" @custom="handleCustom" />
		<div class="mapFooter">
			<ul>
				<li v-for="{ label, color } in legend" :key="label">
					<div class="graphics" :style="`background: ${color}`"></div>
					<span class="text">{{ label }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import TablePage from '@/components/TablePage';
import { cloneDeep } from 'loadsh';
import { setUrlParams, downloadByCreateA /*  getStore, setStore */ } from '@/utils/util';
import { toCanvas } from 'html-to-image';
import { fabric } from 'fabric';
import { Loading } from 'element-ui';
/* 人才地图 */
export default {
	props: { pageName: {} },
	computed: {
		visibleCols() {
			if (this.queryData.planId) {
				if (this.visibleColumn[this.queryData.planId]) {
					return this.visibleColumn[this.queryData.planId];
				}
				// return JSON.parse(getStore(`visible_table_cols_${this.queryData.planId}`) || '[]');
			}
			return [];
		}
	},
	components: {
		TablePage
	},
	data() {
		return {
			tableData: [],
			tableConfig: [],
			tablePage: {
				pageSize: 20,
				perfect: true
			},
			planList: [],
			queryData: {},
			gridOptionsConfig: {
				height: 800,
				headerAlign: 'center',
				// toolbarConfig: {
				// 	// custom: true,
				// 	zoom: true
				// },
				border: true,
				resizable: true,
				// showOverflow: true,
				loading: false,
				columns: [],
				data: [],
				formConfig: {
					// titleWidth: 100,
					items: [
						{
							field: 'planName',
							title: '评估计划',
							span: 6,
							slots: {
								default: () => (
									<el-select
										v-model={this.queryData.planId}
										clearable
										filterable
										size="small"
										style={{ width: '100%' }}
										onchange={this.getMapData}
									>
										{this.planList.map(({ label, value }) => (
											<el-option label={label} value={value}></el-option>
										))}
									</el-select>
								)
							}
						},
						{
							span: 4,
							align: 'left',
							// collapseNode: true,
							itemRender: {
								name: '$button',
								props: { type: 'submit', content: '查询', status: 'primary', icon: 'el-icon-search' },
								events: { click: this.getMapData }
							}
						}
						// {
						// 	span: 12,
						// 	align: 'right',
						// 	// collapseNode: true,
						// 	itemRender: {
						// 		name: '$button',
						// 		props: { type: 'submit', content: '插入地图', status: 'primary', icon: 'el-icon-upload' },
						// 		events: {
						// 			click: () => {
						// 				toPng(document.querySelector('.vxe-table')).then((res) => {
						// 					console.log(res, 'res');
						// 				});
						// 			}
						// 		}
						// 	}
						// }
					]
				},
				toolbarConfig: {
					className: 'toolbar_buts',
					tools: [
						{
							code: 'reset',
							name: '重置',
							status: 'primary',
							// circle: true,
							click: () => {
								if (!this.queryData.planId) {
									this.$message.warning('请选择评估计划');
									return;
								}
								this.$confirm('重置后，该人才地图将恢复到未做任何调整阶段，是否确认?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								})
									.then(() => {
										this.$axios
											.post('/v1/internal/talent/resetTalentMap', { request: { planId: this.queryData.planId } })
											.then((res) => {
												if (res.code) {
													return;
												}
												this.getMapData();
												this.$message.success('重置成功');
											});
									})
									.catch(() => {});
							}
						},
						{
							code: 'print',
							icon: 'vxe-icon-download',
							circle: true,
							click: () => {
								// 打印 下载
								this.getPngCanvas()
									.then((canvas) => {
										downloadByCreateA(canvas.toDataURL('image/png'), '人才地图');
									})
									.catch((error) => {
										this.$message.error('下载出错');
										console.error('html2Image wrong!', error);
									});
							}
						}
					],
					zoom: true,
					custom: true
					// custom: {
					// 	immediate: true,
					// 	isFooter: false
					// }
				},
				customConfig: {
					// storage: { visible: true, resizable: true },
					checkMethod: ({ column }) => !['currentLevel', 'appointment'].includes(column.field)
				},
				mergeCells: []
			},
			legend: [
				{
					label: '完全胜任',
					color: '#42A9DF'
				},
				{
					label: '基本胜任',
					color: '#22B296'
				},
				{
					label: '暂不胜任',
					color: '#F1C40F'
				},
				{
					label: '非人才',
					color: '#404B4C'
				}
			],
			canvasInxs: [],
			canvasPaths: [],
			visibleColumn: {}
		};
	},
	mounted() {
		this.getPlanList();
	},
	methods: {
		filterCols(cols) {
			let colums = cols;

			colums = colums.map((item) => {
				let it = {
					...item,
					field: item.value,
					title: item.label,
					// showOverflow: false,
					minWidth: item.value === 'appointment' ? '200px' : '120px',
					slots: {
						default: ({ row, rowIndex }) => {
							const notDrag = !this.permissionControlBtns(this.pageName, 'Drag');

							if (row[item.value] && !['currentLevel', 'appointment'].includes(item.value)) {
								const raffList = row[item.value] || [];

								return [
									<div
										class="thbox"
										onDrop={(e) => this.cirDragEnd(e)}
										onDragover={(e) => e.preventDefault()}
										data-row={rowIndex}
										data-col={item.value}
										style={`pointer-events:${notDrag && 'none'}`}
									>
										{raffList.map(({ userName, fiveQualities, styleConfig, userId }) => (
											<div
												class={'bgText circular'}
												style={{ background: styleConfig }}
												onDragstart={(e) => this.cirDragStart(e)}
												draggable
												data-row={rowIndex}
												data-col={item.value}
												data-id={userId}
												onclick={() => this.handleStaff(userId)}
											>
												<div class="top">{userName}</div>
												<div class="down">{fiveQualities}</div>
											</div>
										))}
									</div>
								];
							}
							const val = row[item.value];

							let levelColor = '';

							switch (val) {
								case '后备人才池':
									levelColor = '#cf4a50';
									break;
								case '现岗发展':
									levelColor = '#42a9df';
									break;
								case '调整替换':
									levelColor = '#404B4C';
									break;

								default:
									break;
							}
							// 蓝色箭头逻辑
							let lenData = {};

							let [top, bottom] = [6, 6];

							let [topH, bottomH] = [20, 20];

							if (val === '现岗发展') {
								let [upLen, downLen] = [0, 0];

								lenData = this.calculateHeight(row, rowIndex);
								const { currentLength, lastLength, nextLength } = lenData;

								upLen = (currentLength > 1 ? currentLength : 0) + (lastLength > 1 ? lastLength : 0);
								downLen = (currentLength > 1 ? currentLength : 0) + (nextLength > 1 ? nextLength : 0);
								if (upLen > 1) {
									top = 25 * (upLen - 1);
									topH = 25 * upLen - 12;
								}
								if (downLen > 1) {
									bottom = 25 * (downLen - 1);
									bottomH = 25 * downLen - 12;
								}
								if (currentLength > 1) {
									if (lastLength > 1) {
										top = 25 * (upLen - 1) - 20;
										topH = 25 * upLen - 12 - 20;
									}
									if (nextLength > 1) {
										bottom = 25 * (downLen - 1) - 20;
										bottomH = 25 * downLen - 12 - 20;
									}
								}
							}

							// 红色箭头
							let [leftArrD, leftStyle] = [{}, {}];

							let arrowHeight = 0;

							if (val === '后备人才池') {
								leftArrD = this.calculateHeight(row, rowIndex, true);
								const { currentLength, lastLength, last2length } = leftArrD;

								let [_cl, _ll, _l2l] = [currentLength, lastLength, last2length];

								if (currentLength < 1) {
									_cl = 1;
								}
								if (lastLength < 1) {
									_ll = 1;
								}
								if (last2length < 1) {
									_l2l = 1;
								}
								arrowHeight = (60 + 50 * (_cl - 1)) / 2 + 60 + 50 * (_ll - 1) + (60 + 50 * (_l2l - 1)) / 2;

								leftStyle = { top: `-${arrowHeight - 25}px`, height: `${arrowHeight}px`, position: 'absolute', zIndex: 2 };
								if (!this.canvasInxs.map(({ i }) => i).includes(rowIndex)) {
									this.canvasInxs.push({ i: rowIndex, h: arrowHeight });
								} else {
									this.canvasInxs.forEach((val) => {
										if (val.i === rowIndex) {
											val.h = arrowHeight;
										}
									});
								}
							}
							return (
								<div
									class="thbox"
									onDrop={(e) => this.cirDragEnd(e)}
									onDragover={(e) => e.preventDefault()}
									data-row={rowIndex}
									data-col={item.value}
									style="pointer-events:none"
								>
									{item.value === 'currentLevel' && <span class="levelText">{val}</span>}
									{item.value === 'appointment' && (
										<div class="appoint">
											{val === '后备人才池' && rowIndex !== 0 && (
												<div style={leftStyle}>
													<canvas id={`myCanvas${rowIndex}`} width="200" height={arrowHeight}></canvas>
												</div>
											)}
											{val === '现岗发展' && <span class="upArrow" style={{ top: `-${top}px`, height: `${topH}px` }}></span>}
											<div class="echeText" style={{ background: levelColor }}>
												{val}
											</div>
											{val === '现岗发展' && (
												<span class="downArrow" style={{ bottom: `-${bottom}px`, height: `${bottomH}px` }}></span>
											)}
										</div>
									)}
								</div>
							);
						}
					}
				};

				if (item.child && item.child.length) {
					it = { ...it, children: this.filterCols(item.child) };
				}
				return it;
			});
			return colums;
		},
		// 画箭头
		setCanvasSvg(id, h) {
			// 获取 canvas 元素
			let canvas = null;

			if (this.canvasPaths.find(({ id: sid }) => sid === id)) {
				const { canvas: cvs } = this.canvasPaths.find(({ id: sid }) => sid === id);

				cvs.clear();
				cvs.setDimensions({
					width: 200,
					height: h
				});
				canvas = cvs;
			} else {
				canvas = new fabric.Canvas(id);
			}

			// 创建曲线路径
			const path = new fabric.Path(`M 50 ${h - 4} Q 10 ${h - 20} 10 ${h / 2 + 3} T 50 7`, {
				stroke: 'red',
				strokeWidth: 4,
				fill: '',
				selectable: false
			});

			canvas.add(path);

			// 绘制箭头尾部
			const tail = new fabric.Triangle({
				left: 55,
				top: 0,
				width: 20,
				height: 10,
				fill: 'red',
				selectable: false,
				angle: 90
			});

			canvas.add(tail);
			this.canvasPaths.push({ canvas, id });
		},
		// 重画
		reloadCanvas() {
			if (this.canvasPaths?.length) {
				setTimeout(() => {
					this.canvasInxs.forEach(({ i, h }) => {
						this.setCanvasSvg(`myCanvas${i}`, h);
					});
				}, 0);
			}
		},
		// 获取评估计划
		getPlanList() {
			this.$axios
				.post('/v1/internal/talent/getPlanList', {
					request: { pageSize: 1000, pageType: (this.pageName === 'TalentMap_personal' && '2') || '' }
				})
				.then((res) => {
					this.planList = res.list?.map(({ planName, id }) => ({ label: planName, value: id }));
				});
		},
		/* 获取地图数据 */
		getMapData() {
			if (!this.queryData.planId) {
				this.gridOptionsConfig.data = [];
				this.gridOptionsConfig.columns = [];
				return this.$message.warning('请选择评估计划');
			}
			this.canvasPaths = [];
			this.canvasInxs = [];
			this.gridOptionsConfig.loading = true;
			this.$axios.post('/v1/internal/talent/queryTalentMapViewByPlanId', { request: { planId: this.queryData.planId } }).then((res) => {
				this.visibleColumn = {};
				let cols = res.tableConfig || [];

				if (cols.length) {
					cols = this.filterCols([...cols]);
				}
				this.gridOptionsConfig.loading = false;
				this.gridOptionsConfig.data = res.tableData || [];

				this.gridOptionsConfig.columns = cols;
				this.gridOptionsConfig.id = this.queryData.planId;

				let arr = [];

				arr.length = Math.ceil(this.gridOptionsConfig.data.length / 3);
				arr.fill(null);
				arr = arr.map((_, indx) => indx * 3);
				this.gridOptionsConfig.mergeCells = arr.map((v) => ({
					row: v,
					col: 0,
					rowspan: 3,
					colspan: 1
				}));

				setTimeout(() => {
					this.canvasInxs.forEach(({ i, h }) => {
						this.setCanvasSvg(`myCanvas${i}`, h);
					});
				}, 0);
			});
		},

		// 点击人跳转
		handleStaff(userId) {
			if (!userId) {
				return;
			}
			this.$router.push({
				path: '/useTalentCorrectly/talentEvaluation/evaluationDetail',
				query: { params: setUrlParams({ userId, type: 'view', planId: this.queryData.planId }) }
			});
		},
		// 计算 箭头长度
		calculateHeight(row, rowIndex, need2Last) {
			let allData = cloneDeep(this.gridOptionsConfig.data);

			if (this.visibleCols.length > 0) {
				allData = allData.map((v) => {
					const obj = {};

					this.visibleCols.forEach(({ key }) => {
						obj[key] = v[key];
					});
					return obj;
				});
			}
			const currentRow = allData[rowIndex];
			const lastRow = allData[rowIndex - 1];
			const nextRow = allData[rowIndex + 1];

			let lastLength = 0;

			let nextLength = 0;

			let currentLength = 0;

			if (lastRow) {
				Object.keys(lastRow).forEach((key) => {
					if (Array.isArray(lastRow[key]) && lastRow[key].length > lastLength) {
						lastLength = lastRow[key].length;
					}
				});
			}
			if (nextRow) {
				Object.keys(nextRow).forEach((key) => {
					if (Array.isArray(nextRow[key]) && nextRow[key].length > nextLength) {
						nextLength = nextRow[key].length;
					}
				});
			}
			let last2length = 0;

			if (need2Last) {
				const last2Row = allData[rowIndex - 2] || {};

				Object.keys(last2Row).forEach((key) => {
					if (Array.isArray(last2Row[key]) && last2Row[key].length > last2length) {
						last2length = last2Row[key].length;
					}
				});
			}
			Object.keys(currentRow).forEach((key) => {
				if (Array.isArray(currentRow[key]) && currentRow[key].length > currentLength) {
					currentLength = currentRow[key].length;
				}
			});
			return {
				lastLength,
				nextLength,
				currentLength,
				last2length
			};
		},
		// 拖拽开始
		cirDragStart(e) {
			this.dragStartData = {
				rowIndex: e.currentTarget.getAttribute('data-row'),
				colKey: e.currentTarget.getAttribute('data-col'),
				id: e.currentTarget.getAttribute('data-id')
			};
		},
		// 拖拽结束
		cirDragEnd(e) {
			e.preventDefault();

			const target = e.currentTarget;
			const [rowIndex, colKey] = [target.getAttribute('data-row'), target.getAttribute('data-col')];

			if (this.dragStartData.rowIndex === rowIndex && this.dragStartData.colKey === colKey) {
				return;
			}
			if (['currentLevel', 'appointment'].includes(colKey) || this.dragStartData.colKey !== colKey) {
				return this.$message.warning('不能移动到此位置！');
			}
			const dList = cloneDeep(this.gridOptionsConfig.data);
			// 移动请求
			const { appointment: oldAppointment, currentLevel: oldCurrentLevel } = dList[this.dragStartData.rowIndex];
			const { appointment: newAppointment, currentLevel: newCurrentLevel } = dList[rowIndex];
			const params = {
				planId: this.queryData.planId,
				userId: this.dragStartData.id,
				oldAppointment,
				oldCurrentLevel,
				newAppointment,
				newCurrentLevel
			};

			this.$axios.post('/v1/internal/talent/changeMapPosition', { request: params }).then((res) => {
				if (res && res.code === 0) {
					this.$message.success('移动成功');
					this.gridOptionsConfig.data = this.gridOptionsConfig.data.map((it, index) => {
						let data = it;

						//  移动前 处理
						if (index === Number(this.dragStartData.rowIndex)) {
							const sdata = this.dragStartData;

							data = {
								...it,
								[sdata.colKey]: dList[sdata.rowIndex][sdata.colKey].filter((it) => it.userId.toString() !== sdata.id)
							};
						}
						// 增加
						if (index === Number(rowIndex)) {
							const sdata = this.dragStartData;

							data = {
								...data,
								[colKey]: dList[sdata.rowIndex][sdata.colKey]
									.filter(({ userId }) => userId.toString() === sdata.id)
									.concat(dList[rowIndex][colKey] || [])
							};
						}
						return data;
					});

					this.reloadCanvas();
				}
			});
		},
		handleCustom(e) {
			const { visibleColumn } = e.$grid.getTableColumn();

			if (this.queryData.planId && ['confirm', 'reset', 'close'].includes(e.type)) {
				const vcols = visibleColumn.map(({ field, title }) => ({ title, key: field }));

				this.$set(this.visibleColumn, this.queryData.planId, vcols);
				// setStore(`visible_table_cols_${this.queryData.planId}`, JSON.stringify(vcols));
				this.reloadCanvas();
			}
		},
		getPngCanvas() {
			const elHeader = document.querySelector('.vxe-table--header');
			const elBody = document.querySelector('.vxe-table--body');

			elBody.style.background = '#fff';
			const loading = Loading.service({ target: '.xtable' });
			const mergeCanvases = (canvas1, canvas2) => {
				const { width } = canvas1;
				const { height } = canvas1;
				const mergedCanvas = document.createElement('canvas');

				mergedCanvas.width = width;
				mergedCanvas.height = height + canvas2.height;

				const mergedCtx = mergedCanvas.getContext('2d');

				mergedCtx.drawImage(canvas1, 0, 0);
				mergedCtx.drawImage(canvas2, 0, height);
				return mergedCanvas;
			};

			return Promise.all([toCanvas(elHeader), toCanvas(elBody)])
				.then((res) => {
					loading.close();
					if (res?.length) {
						const mergedCanvas = mergeCanvases(res[0], res[1]);

						return mergedCanvas;
					}
				})
				.catch(() => {
					loading.close();
				});
		}
	}
};
</script>

<style lang="scss">
.xtable {
	.toolbar_buts {
		position: absolute;
		top: 0;
		right: 0;
		.vxe-button {
			margin-left: 4px;
		}
		.vxe-custom--wrapper {
			margin-left: 0;
		}
	}
	.is--maximize {
		.toolbar_buts {
			right: 20px;
		}
	}
	.vxe-grid--form-wrapper {
		margin: 0 0 10px 0;
	}
	.thbox {
		text-align: center;
		min-height: 40px;
		.circular {
			cursor: pointer;
			height: 40px;
			width: 94px;
			border-radius: 124px / 50px;
			margin: 10px auto;
			text-align: center;
			user-select: none;
			.down,
			.top {
				height: 50%;
			}
			.down {
				border-top: 2px solid red;
			}
		}
		.bgText {
			color: #fff;
			// #ffff00  #70ad47
		}
		.levelText {
			font-size: 20px;
			font-weight: bold;
		}
		.echeText {
			display: inline-block;
			height: 40px;
			width: 100px;
			border-radius: 7px;
			color: #fff;
			line-height: 40px;
			margin: 10px 0;
		}
		.appoint {
			display: inline-flex;
			justify-content: center;
			position: relative;
			user-select: none;
			.upArrow,
			.downArrow {
				display: inline-block;
				height: 20px;
				width: 24px;
				position: absolute;
				background: #42a9df;
				z-index: 1;
			}
			.upArrow {
				top: -6px;
				&::before {
					content: ' ';
					border: 20px solid #42a9df;
					border-bottom: 14px solid #42a9df;
					display: inline-block;
					border-color: transparent transparent #42a9df transparent;
					position: absolute;
					top: -32px;
					left: -8px;
					z-index: 1;
				}
			}
			.downArrow {
				bottom: -6px;
				&::before {
					content: ' ';
					border: 20px solid #42a9df;
					border-top: 14px solid #42a9df;
					display: inline-block;
					border-color: #42a9df transparent transparent transparent;
					position: absolute;
					bottom: -32px;
					left: -8px;
					z-index: 1;
				}
			}
			.arrow {
				width: 0;
				height: 0;
				display: inline-block;
				border: 12px solid red;
				border-top: 10px solid red;
				border-color: transparent transparent transparent red;
				position: absolute;
			}
			.leftArrow {
				// &::before {
				// 	content: ' ';
				// 	border: 12px solid red;
				// 	border-top: 10px solid red;
				// 	display: inline-block;
				// 	border-color: transparent transparent transparent red;
				// 	position: absolute;
				// 	top: 0;
				// 	left: 32px;
				// }
				top: -110px;
				position: absolute;
				background: transparent;
				width: 180px;
				height: 154px;
				border-radius: 50%;
				border: 7px solid red;
				z-index: 2;
				clip-path: polygon(14% -4%, 27% 12%, 0% 3600%, 0% 0%);
				left: -48px;
			}
		}
	}
}
.el-message {
	z-index: 99999 !important;
}
.mapFooter {
	// text-align: center;
	margin: 0 0 20px 20px;
	ul {
		li {
			align-items: center;
			justify-content: center;
			display: inline-flex;
			.text {
				margin: 0 10px;
			}
		}
		display: inline-flex;
		.graphics {
			background: #666;
			display: inline-block;
			height: 28px;
			width: 66px;
			border-radius: 124px / 50px;
		}
	}
}
</style>
