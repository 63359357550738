<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="评估数据库" name="1" v-if="permissionControl('EvaluationDatabase')">
				<EvaluationDatabaseTabs ref="pane1" pageName="EvaluationDatabase" v-if="activeName === '1'"></EvaluationDatabaseTabs>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="人才地图" name="2" v-if="permissionControl('TalentMap')">
				<Map ref="pane2" pageName="TalentMap" v-if="activeName === '2'"></Map>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="评估报告" name="3" v-if="permissionControl('AssessmentReport')">
				<ProjectReport ref="pane3" pageName="AssessmentReport" v-if="activeName === '3'"></ProjectReport>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
/* 评估中心 */
import EvaluationDatabaseTabs from './evaluationDatabaseTabs'; // 评估列表
import Map from '@/views/talent-assessment/list/map';
import ProjectReport from '@/views/talent-assessment/evaluationResult/projectReport';

// import * as api from "@/api/workbench";
export default {
	name: 'assessmentCenter',
	components: {
		EvaluationDatabaseTabs,
		Map,
		ProjectReport
	},
	data() {
		return {
			activeName: '',
			listQuery: {}
		};
	},

	computed: {},
	created() {},
	methods: {},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'EvaluationDatabase' },
			{ name: '2', key: 'TalentMap' },
			{ name: '3', key: 'AssessmentReport' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`pane${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>
